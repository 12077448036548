import axios from "axios";
import store from "@/store/store.js"
import Router from '@/router'

// 跨域的域名配置
let cros_doman = "*,*.trademanager.com,127.0.0.1,192.168.3.*,*.12980.com,vip.b2b3.loc";
cros_doman = cros_doman +","+ process.env.VUE_APP_B2B3_OPEN_API_URL
 +","+process.env.VUE_APP_B2B3_EMAIL_OPEN_API_URL,
 +","+ process.env.VUE_APP_B2B3_SEARCH_OPEN_API_URL,
 +","+process.env.VUE_APP_B2B3_DATA_OPEN_API_URL
;

//创建axios实例
let service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,

// baseURL:'http://localhost:8801',
//   timeout: 5000,
  withCredentials: true,
  headers: {
    //增加跨域配置
    "Content-Type": "application/json;charset=UTF-8",
//    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": cros_doman,
  },
});

service.interceptors.request.use(
	config => {
		// console.log("====header"+store.state.token)
		if (store.state.token) {
			//登录后
			if(store.state.isLogin && store.state.token && store.state.userInfo){
				config.headers.common['token'] = store.state.token
			}else{
				cancelLogin()
			}
			
		}
		
		return config
	},
	err => {
		return Promise.reject(err)
	}
)

service.interceptors.response.use(
	response => {
		return response
	},
	 err => {
		if (err.code=="ERR_NETWORK"  && err.response.status  === 0) {
			cancelLogin()
		}
		return Promise.reject(err)
	}
)


function cancelLogin(){
	store.commit('delToken')
	let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
	// if(isMobile==null){
	// 	Router.push("/customsData")
	// }else{
	// 	Router.push("/m")
	// }
	// Router.push("/")
	window.location.href='/'
}
export default {
	service,
	//get请求
	get(url, data) {
		return service({
			url: url,
			method: "get",
			params: data
		});
	},
	//post请求
	post(url, data) {
		return service({
			url: url,
			method: "post",
			dataType: "json",
			contentType: "application/json;charset=UTF-8",
			data: JSON.stringify(data)
		});
	},
	//put请求
	put(url, data) {
		return service({
			url: url,
			method: "put",
			dataType: "json",
			contentType: "application/json;charset=UTF-8",
			data: JSON.stringify(data)
		});
	},
	//删除请求
	del(url) {
		return service({
			url: url,
			method: "delete"
		});
	}
}
